import React, { memo } from 'react'
import PreviewDescription from '@images/preview_description.png'
import './MainDescription.scss'

const MainDescription = () => {
  return (
    <article className="main-description">
      <div className="main-description__wrapper--image">
        <img className="main-description__image" src={PreviewDescription} height={'457px'} width={'538px'} />
      </div>
      <div className="main-description__wrapper--content">
        <h3 className="main-description__title">Не просто место</h3>
        <p className="main-description__text">
          Точки фастфуда и кофейня, барбершоп, студии макияжа и фотостудии, велопрокат, школы фотографии и иностранных
          языков, тату-студия, типография, уникальные магазины и индивидуальные офисы, выставки, фестивали, зал для
          работы и мероприятий – все это Кластер&nbsp;c52.
        </p>
      </div>
    </article>
  )
}

export default memo(MainDescription)
