import React, { memo } from 'react'
import { SEO } from '@components'
import Preview from '@images/preview__heading.png'
import PreviewMobile from '@images/preview__heading_mobile.png'
import './MainHeading.scss'

const MainHeading = () => {
  return (
    <section className="main">
      <SEO title={'Home'} />
      <h1 className="main__title">Кластер C52 – это креативное пространство для работы, отдыха и общения</h1>
      <h2 className="main__subtitle">В самом центре города</h2>
      <div className="main__preview-wrapper">
        <picture>
          <source srcSet={PreviewMobile} media="(max-width: 900px)" />
          <source srcSet={Preview} />
          <img className="main__preview" src={Preview} />
        </picture>
      </div>
    </section>
  )
}

export default memo(MainHeading)
