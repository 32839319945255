import React, { memo } from 'react'
import IconGlass from '@images/rent_icon__glass.png'
import IconHome from '@images/rent_icon__home.png'
import IconMapMark from '@images/rent_icon__map_mark.png'
import './MainRent.scss'

const RentSection = () => {
  return (
    <article className="rent-section" id="rent">
      <h2 className="rent-section__title grey">Аренда</h2>
      <section className="rent-section__description description">
        <h3 className="description__title">Мы рады новым друзьям, разделяющим наши ценности.</h3>
        <div className="description__wrapper content">
          <p className="content__text">
            В аренду сдаются офисы и торговые площади, а также мы предоставляем наш зал как пространство для проведения
            выставок, маркетов и прочих мероприятий.
          </p>
          <ul className="content__list">
            <li className="list__item">
              <img src={IconMapMark} alt="mark" width="44px" height="44px" />
              Удобное расположение
            </li>
            <li className="list__item">
              <img src={IconHome} alt="mark" width="44px" height="44px" />
              Круглосуточный доступ для арендаторов
            </li>
            <li className="list__item">
              <img src={IconGlass} alt="mark" width="44px" height="44px" />
              Надежная охрана и постоянное видеонаблюдение
            </li>
          </ul>
        </div>
      </section>
      <section className="rent-section__how-renant how-renant">
        <h2 className="how-renant__title">Как стать арендатором кластера?</h2>
        <ul className="how-renant__list">
          <li className="list__item item">
            <p className="text dark-grey">Выбираете свободное помещение</p>
            <span className="grey">
              Актуальная информация об аренде в &zwnj;
              <a className="dark-grey" target="_blank" href="https://t.me/EdoAsla">
                телеграм
              </a>
            </span>
            <a className="text__link red" href="/documents/FloorPlans.pdf" download>
              Схемы этажей
            </a>
          </li>
          <li className="list__item item">
            <p className="text dark-grey">Вы звоните нам и мы договариваемся об аренде</p>
            <a className="text__link red" href="tel:+79185735342">
              +7 (918) 573-53-42
            </a>
          </li>
          <li className="list__item item">
            <p className="text dark-grey">Мы с вами подписываем договор</p>
            <a className="text__link red" href="/documents/ContractTemplateC52.doc" download>
              Шаблон договора
            </a>
          </li>
          <li className="list__item item">
            <p className="text dark-grey">Вы въезжаете в свой новый офис</p>
          </li>
        </ul>
      </section>
    </article>
  )
}

export default memo(RentSection)
