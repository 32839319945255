import React, { memo } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Slider } from '@components'
import { MainPage } from '@pages/components'
import './main.scss'

interface HomeProps extends RouteComponentProps {}

const Home: React.FC<HomeProps> = props => {
  return (
    <>
      <MainPage.MainHeading />
      <MainPage.MainDescription />
      <article className="map-section">
        <h2 className="map-section__title">Карта</h2>
        <Slider />
      </article>
      <MainPage.MainRent />
    </>
  )
}

export default memo(Home)
